import { useFetchInstance } from "~/api";
import { SignInForm, SignUpForm, SignInResponse, SignUpResponse } from "@/types/api/auth.types";

export const authApi = {
  /**
   * Lоgin
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-PostApiV1Authorizations
   * @param data
   * @returns {*}
   */
  signIn(data: SignInForm) {
    return useFetchInstance<SignInResponse>("/authorizations", {
      method: "POST",
      body: data,
    });
  },

  /**
   * Register
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-PostApiV1AuthorizationsSignUp
   * @param data
   * @returns {*}
   */
  signUp(data: SignUpForm) {
    return useFetchInstance<SignUpResponse>("/authorizations/sign-up", {
      method: "POST",
      body: data,
    });
  },

  /**
   * Logout
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-DeleteApiV1Authorizations
   * @returns {*}
   */
  signOut() {
    return useFetchInstance("/authorizations", {
      method: "DELETE",
    });
  },
};
